import { ScannerQRCodeConfig } from "./ngx-scanner-qrcode.options";

export const WASMPROJECT = "assets/wasm/index.js";
export const WASMREMOTE = "https://cdn.jsdelivr.net/npm/ngx-scanner-qrcode@1.6.3/wasm/index.js";
export const WASMREMOTELATEST = "https://cdn.jsdelivr.net/npm/ngx-scanner-qrcode@latest/wasm/index.js";

export const BEEP = `data:audio/mpeg;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU4LjI5LjEwMAAAAAAAAAAAAAAA/+M4wAAAAAAAAAAAAEluZm8AAAAPAAAABQAAAkAAgICAgICAgICAgICAgICAgICAgKCgoKCgoKCgoKCgoKCgoKCgoKCgwMDAwMDAwMDAwMDAwMDAwMDAwMDg4ODg4ODg4ODg4ODg4ODg4ODg4P//////////////////////////AAAAAExhdmM1OC41NAAAAAAAAAAAAAAAACQEUQAAAAAAAAJAk0uXRQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/+MYxAANQAbGeUEQAAHZYZ3fASqD4P5TKBgocg+Bw/8+CAYBA4XB9/4EBAEP4nB9+UOf/6gfUCAIKyjgQ/Kf//wfswAAAwQA/+MYxAYOqrbdkZGQAMA7DJLCsQxNOij///////////+tv///3RWiZGBEhsf/FO/+LoCSFs1dFVS/g8f/4Mhv0nhqAieHleLy/+MYxAYOOrbMAY2gABf/////////////////usPJ66R0wI4boY9/8jQYg//g2SPx1M0N3Z0kVJLIs///Uw4aMyvHJJYmPBYG/+MYxAgPMALBucAQAoGgaBoFQVBUFQWDv6gZBUFQVBUGgaBr5YSgqCoKhIGg7+IQVBUFQVBoGga//SsFSoKnf/iVTEFNRTMu/+MYxAYAAANIAAAAADEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV`;

export const MEDIA_STREAM_DEFAULT: MediaStreamConstraints = {
  audio: false,
  video: true
};

export const CANVAS_STYLES_LAYER: CanvasRenderingContext2D | any = {
  lineWidth: 1,
  strokeStyle: 'green',
  fillStyle: '#55f02880'
}

export const CANVAS_STYLES_TEXT: CanvasRenderingContext2D | any = {
  font: '15px serif',
  strokeStyle: '#fff0',
  fillStyle: '#ff0000'
}

export const CONFIG_DEFAULT: ScannerQRCodeConfig = {
  src: '',
  fps: 30,
  vibrate: 300,
  decode: 'utf-8',
  isBeep: true,
  constraints: MEDIA_STREAM_DEFAULT,
  canvasStyles: [CANVAS_STYLES_LAYER, CANVAS_STYLES_TEXT]
};