import { Injectable } from '@angular/core';
import { AsyncSubject } from 'rxjs';
import { AS_COMPLETE, COMPRESS_IMAGE_FILE, FILES_TO_SCAN } from './ngx-scanner-qrcode.helper';
import { ScannerQRCodeConfig, ScannerQRCodeSelectedFiles } from './ngx-scanner-qrcode.options';

@Injectable({
  providedIn: 'root'
})
export class NgxScannerQrcodeService {

  /**
   * loadFiles
   * @param files 
   * @param quality 
   * @param type 
   * @returns 
   */
  public loadFiles(files: File[] = [], quality?: number, type?: string): AsyncSubject<ScannerQRCodeSelectedFiles[]> {
    const as = new AsyncSubject<ScannerQRCodeSelectedFiles[]>();
    COMPRESS_IMAGE_FILE(files, quality, type).then((_files: any) => {
      Promise.all(Object.assign([], files).map(m => this.readAsDataURL(m))).then((img: ScannerQRCodeSelectedFiles[]) => AS_COMPLETE(as, img)).catch((error: any) => AS_COMPLETE(as, null, error));
    });
    return as;
  }

  /**
   * loadFilesToScan
   * @param files 
   * @param config 
   * @param quality 
   * @param type 
   * @returns 
   */
  public loadFilesToScan(files: File[] = [], config: ScannerQRCodeConfig, quality?: number, type?: string): AsyncSubject<ScannerQRCodeSelectedFiles[]> {
    return FILES_TO_SCAN(files, config, quality, type);
  }

  /**
   * readAsDataURL
   * @param file 
   * @returns 
   */
  private readAsDataURL(file: File): Promise<ScannerQRCodeSelectedFiles> {
    /** drawImage **/
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const objectFile = {
          name: file.name,
          file: file,
          url: URL.createObjectURL(file)
        };
        resolve(objectFile);
      }
      fileReader.onerror = (error: any) => reject(error);
      fileReader.readAsDataURL(file);
    })
  }
}