import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { AsyncSubject, BehaviorSubject } from 'rxjs';
import { CANVAS_STYLES_LAYER, CANVAS_STYLES_TEXT, CONFIG_DEFAULT, MEDIA_STREAM_DEFAULT } from './ngx-scanner-qrcode.default';
import { AS_COMPLETE, BLOB_TO_FILE, CANVAS_TO_BLOB, DRAW_RESULT_APPEND_CHILD, FILES_TO_SCAN, HAS_OWN_PROPERTY, OVERRIDES, PLAY_AUDIO, REMOVE_RESULT_PANEL, RESET_CANVAS, UPDATE_WIDTH_HEIGHT_VIDEO, VIBRATE, WASM_READY } from './ngx-scanner-qrcode.helper';
import { LOAD_WASM } from './ngx-scanner-qrcode.loader';
import { ScannerQRCodeConfig, ScannerQRCodeDevice, ScannerQRCodeResult, ScannerQRCodeSelectedFiles } from './ngx-scanner-qrcode.options';
declare var zbarWasm: any;

@Component({
  selector: 'ngx-scanner-qrcode',
  template: `<div #resultsPanel class="origin-overlay"></div><canvas #canvas class="origin-canvas"></canvas><video #video playsinline class="origin-video"></video>`,
  styleUrls: ['./ngx-scanner-qrcode.component.scss'],
  host: { 'class': 'ngx-scanner-qrcode' },
  exportAs: 'scanner',
  inputs: ['src', 'fps', 'vibrate', 'decode', 'isBeep', 'config', 'constraints', 'canvasStyles'],
  outputs: ['event'],
  queries: {
    video: new ViewChild('video'),
    canvas: new ViewChild('canvas'),
    resultsPanel: new ViewChild('resultsPanel')
  },
  encapsulation: ViewEncapsulation.None
})
export class NgxScannerQrcodeComponent implements OnInit, OnDestroy {

  /**
   * Element
   * playsinline required to tell iOS safari we don't want fullscreen
   */
  public video!: ElementRef<HTMLVideoElement>;
  public canvas!: ElementRef<HTMLCanvasElement>;
  public resultsPanel!: ElementRef<HTMLDivElement>;

  /**
   * EventEmitter
   */
  public event = new EventEmitter<ScannerQRCodeResult[]>();

  /**
   * Input
   */
  public src: string | undefined = CONFIG_DEFAULT.src;
  public fps: number | undefined = CONFIG_DEFAULT.fps;
  public vibrate: number | undefined = CONFIG_DEFAULT.vibrate;
  public decode: string | undefined = CONFIG_DEFAULT.decode;
  public isBeep: boolean | undefined = CONFIG_DEFAULT.isBeep;
  public config: ScannerQRCodeConfig = CONFIG_DEFAULT;
  public constraints: MediaStreamConstraints | any = CONFIG_DEFAULT.constraints;
  public canvasStyles: CanvasRenderingContext2D[] | any[] = [CANVAS_STYLES_LAYER, CANVAS_STYLES_TEXT];

  /**
   * Export
  */
  public isStart: boolean = false;
  public isPause: boolean = false;
  public isLoading: boolean = false;
  public isTorch: boolean = false;
  public data = new BehaviorSubject<ScannerQRCodeResult[]>([]);
  public devices = new BehaviorSubject<ScannerQRCodeDevice[]>([]);
  public deviceIndexActive: number = 0;

  /**
   * Private
  */
  private rAF_ID: any;
  private dataForResize: ScannerQRCodeResult[] = [];
  private ready = new AsyncSubject<boolean>();

  private STATUS = {
    startON: () => this.isStart = true,
    pauseON: () => this.isPause = true,
    loadingON: () => this.isLoading = true,
    startOFF: () => this.isStart = false,
    pauseOFF: () => this.isPause = false,
    loadingOFF: () => this.isLoading = false,
    torchOFF: () => this.isTorch = false,
  }

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.overrideConfig();
    LOAD_WASM(this.ready, this.renderer).subscribe(() => {
      if (this.src) {
        this.loadImage(this.src);
      }
      this.resize();
    });
  }

  /**
   * start
   * @param playDeviceCustom 
   * @returns 
   */
  public start(playDeviceCustom?: Function): AsyncSubject<any> {
    const as = new AsyncSubject<any>();
    if (this.isStart) {
      // Reject
      AS_COMPLETE(as, false);
    } else {
      // fix safari
      this.safariWebRTC(as, playDeviceCustom);
    }
    return as;
  }

  /**
   * stop
   * @returns 
   */
  public stop(): AsyncSubject<any> {
    this.STATUS.pauseOFF();
    this.STATUS.startOFF();
    this.STATUS.torchOFF();
    this.STATUS.loadingOFF();
    const as = new AsyncSubject<any>();
    try {
      clearTimeout(this.rAF_ID);
      (this.video.nativeElement.srcObject as MediaStream).getTracks().forEach((track: MediaStreamTrack) => {
        track.stop();
        AS_COMPLETE(as, true);
      });
      this.dataForResize = [];
      RESET_CANVAS(this.canvas.nativeElement);
      REMOVE_RESULT_PANEL(this.resultsPanel.nativeElement);
    } catch (error) {
      AS_COMPLETE(as, false, error as any);
    }
    return as;
  }

  /**
   * play
   * @returns 
   */
  public play(): AsyncSubject<any> {
    const as = new AsyncSubject<any>();
    if (this.isPause) {
      this.video.nativeElement.play();
      this.STATUS.pauseOFF();
      this.requestAnimationFrame();
      AS_COMPLETE(as, true);
    } else {
      AS_COMPLETE(as, false);
    }
    return as;
  }

  /**
   * pause
   * @returns 
   */
  public pause(): AsyncSubject<any> {
    const as = new AsyncSubject<any>();
    if (this.isStart) {
      clearTimeout(this.rAF_ID);
      this.video.nativeElement.pause();
      this.STATUS.pauseON();
      AS_COMPLETE(as, true);
    } else {
      AS_COMPLETE(as, false);
    }
    return as;
  }

  /**
   * playDevice
   * @param deviceId 
   * @param as 
   * @returns 
   */
  public playDevice(deviceId: string, as: AsyncSubject<any> = new AsyncSubject<any>()): AsyncSubject<any> {
    const constraints = this.getConstraints();
    const existDeviceId = (this.isStart && constraints) ? constraints.deviceId !== deviceId : true;
    switch (true) {
      case deviceId === 'null' || deviceId === 'undefined' || !deviceId:
        stop();
        this.stop();
        AS_COMPLETE(as, false);
        break;
      case deviceId && existDeviceId:
        stop();
        this.stop();
        // Loading on
        this.STATUS.loadingON();
        this.deviceIndexActive = this.devices.value.findIndex((f: ScannerQRCodeDevice) => f.deviceId === deviceId);
        const constraints = { ...this.constraints, audio: false, video: { deviceId: deviceId, ...this.constraints.video } };
        // MediaStream
        navigator.mediaDevices.getUserMedia(constraints).then((stream: MediaStream) => {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.onloadedmetadata = () => {
            this.video.nativeElement.play();
            this.requestAnimationFrame();
            AS_COMPLETE(as, true);
            this.STATUS.startON();
            this.STATUS.loadingOFF();
          }
        }).catch(error => {
          this.eventEmit(false);
          AS_COMPLETE(as, false, error as any);
          this.STATUS.startOFF();
          this.STATUS.loadingOFF();
        });
        break;
      default:
        AS_COMPLETE(as, false);
        this.STATUS.loadingOFF();
        break;
    }
    return as;
  }

  /**
   * loadImage
   * @param src 
   * @returns 
   */
  public loadImage(src: string): AsyncSubject<any> {
    const as = new AsyncSubject<any>();
    // Loading on
    this.STATUS.startOFF();
    this.STATUS.loadingON();
    // Set the src of this Image object.
    const image = new Image();
    // Setting cross origin value to anonymous
    image.setAttribute('crossOrigin', 'anonymous');
    // When our image has loaded.
    image.onload = () => {
      WASM_READY() && this.drawImage(image, (flag: boolean) => {
        AS_COMPLETE(as, flag);
        this.STATUS.startOFF();
        this.STATUS.loadingOFF();
      });
    };
    // Set src
    image.src = src;
    return as;
  }

  /**
   * torcher
   * @returns 
   */
  public torcher(): AsyncSubject<any> {
    const as = this.applyConstraints({ advanced: [{ torch: this.isTorch }] });
    as.subscribe(() => false, () => this.isTorch = !this.isTorch);
    return as;
  }

  /**
   * applyConstraints
   * @param constraints 
   * @param deviceIndex 
   * @returns 
   */
  public applyConstraints(constraints: MediaTrackConstraintSet | MediaTrackConstraints | any, deviceIndex = 0): AsyncSubject<any> {
    const as = new AsyncSubject<any>();
    if (this.isStart) {
      const stream = this.video.nativeElement.srcObject as MediaStream;
      if (deviceIndex !== null || deviceIndex !== undefined || !Number.isNaN(deviceIndex)) {
        const videoTrack = stream.getVideoTracks()[deviceIndex] as MediaStreamTrack;
        const imageCapture = new (window as any).ImageCapture(videoTrack);
        imageCapture.getPhotoCapabilities().then(async () => {
          await videoTrack.applyConstraints(constraints);
          UPDATE_WIDTH_HEIGHT_VIDEO(this.video.nativeElement, this.canvas.nativeElement);
          AS_COMPLETE(as, this.getConstraints());
        }).catch((error: any) => {
          switch (error && error.name) {
            case 'NotFoundError':
            case 'DevicesNotFoundError':
              AS_COMPLETE(as, false, 'Required track is missing' as string);
              break;
            case 'NotReadableError':
            case 'TrackStartError':
              AS_COMPLETE(as, false, 'Webcam or mic are already in use' as string);
              break;
            case 'OverconstrainedError':
            case 'ConstraintNotSatisfiedError':
              AS_COMPLETE(as, false, 'Constraints can not be satisfied by avb. devices' as string);
              break;
            case 'NotAllowedError':
            case 'PermissionDeniedError':
              AS_COMPLETE(as, false, 'Permission denied in browser' as string);
              break;
            case 'TypeError':
              AS_COMPLETE(as, false, 'Empty constraints object' as string);
              break;
            default:
              AS_COMPLETE(as, false, error as any);
              break;
          }
        });
      } else {
        AS_COMPLETE(as, false, 'Please check again deviceIndex' as string);
      }
    } else {
      AS_COMPLETE(as, false, 'Please start the scanner' as string);
    }
    return as;
  };

  /**
   * getConstraints
   * @param deviceIndex 
   * @returns 
   */
  public getConstraints(deviceIndex = 0): MediaTrackConstraintSet | MediaTrackConstraints {
    const stream = this.video.nativeElement.srcObject as MediaStream;
    const videoTrack = stream && stream.getVideoTracks()[deviceIndex] as MediaStreamTrack;
    return videoTrack && videoTrack.getConstraints() as MediaTrackConstraints;
  }

  /**
   * download
   * @param fileName 
   * @param quality 
   * @param type 
   * @returns 
   */
  public download(fileName: string = `ngx_scanner_qrcode_${Date.now()}.png`, quality?: number, type?: string): AsyncSubject<ScannerQRCodeSelectedFiles[]> {
    const as = new AsyncSubject<any>();
    const run = async () => {
      const blob = await CANVAS_TO_BLOB(this.canvas.nativeElement);
      const file = BLOB_TO_FILE(blob, fileName);
      FILES_TO_SCAN([file], this.config, quality, type, as).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
        res.forEach((item: ScannerQRCodeSelectedFiles) => {
          if (item.data && item.data.length) {
            const link = document.createElement('a');
            link.href = item.url;
            link.download = item.name;
            link.click();
            link.remove()
          }
        });
      });
    }
    run();
    return as;
  }

  /**
   * resize
   * Draw again!
   */
  private resize(): void {
    window.addEventListener("resize", () => {
      DRAW_RESULT_APPEND_CHILD(this.dataForResize as any, this.canvas.nativeElement, this.resultsPanel.nativeElement, this.canvasStyles);
      UPDATE_WIDTH_HEIGHT_VIDEO(this.video.nativeElement, this.canvas.nativeElement);
    });
  }

  /**
   * overrideConfig
   */
  private overrideConfig(): void {
    if (HAS_OWN_PROPERTY(this.config, 'src')) this.src = this.config.src;
    if (HAS_OWN_PROPERTY(this.config, 'fps')) this.fps = this.config.fps;
    if (HAS_OWN_PROPERTY(this.config, 'vibrate')) this.vibrate = this.config.vibrate;
    if (HAS_OWN_PROPERTY(this.config, 'decode')) this.decode = this.config.decode;
    if (HAS_OWN_PROPERTY(this.config, 'isBeep')) this.isBeep = this.config.isBeep;
    if (HAS_OWN_PROPERTY(this.config, 'constraints')) this.constraints = OVERRIDES('constraints', this.config, MEDIA_STREAM_DEFAULT);
    if (HAS_OWN_PROPERTY(this.config, 'canvasStyles') && this.config.canvasStyles.length === 2) this.canvasStyles = this.config.canvasStyles;
  }

  /**
   * safariWebRTC
   * Fix issue on safari
   * https://webrtchacks.com/guide-to-safari-webrtc
   * @param as 
   * @param playDeviceCustom 
   */
  private safariWebRTC(as: AsyncSubject<any>, playDeviceCustom?: Function): void {
    // Loading on
    this.STATUS.startOFF();
    this.STATUS.loadingON();
    navigator.mediaDevices.getUserMedia(this.constraints).then((stream: MediaStream) => {
      stream.getTracks().forEach(track => track.stop());
      this.loadAllDevices(as, playDeviceCustom);
    }).catch(error => {
      AS_COMPLETE(as, false, error as any);
      this.STATUS.startOFF();
      this.STATUS.loadingOFF();
    });
  }

  /**
   * loadAllDevices
   * @param as 
   * @param playDeviceCustom 
   */
  private loadAllDevices(as: AsyncSubject<any>, playDeviceCustom?: Function): void {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      let cameraDevices: ScannerQRCodeDevice[] = devices.filter(f => f.kind == 'videoinput');
      this.devices.next(cameraDevices);
      if (cameraDevices.length > 0) {
        AS_COMPLETE(as, cameraDevices);
        playDeviceCustom ? playDeviceCustom(cameraDevices) : this.playDevice(cameraDevices[0].deviceId);
      } else {
        AS_COMPLETE(as, false, 'No camera detected.' as any);
        this.STATUS.startOFF();
        this.STATUS.loadingOFF();
      }
    }).catch(error => {
      AS_COMPLETE(as, false, error as any);
      this.STATUS.startOFF();
      this.STATUS.loadingOFF();
    });
  }

  /**
   * drawImage
   * @param element 
   * @param callback 
   */
  private async drawImage(element: HTMLImageElement | HTMLVideoElement, callback: Function = () => { }): Promise<void> {
    // Get the canvas element by using the getElementById method.
    const canvas = this.canvas.nativeElement;
    // Get a 2D drawing context for the canvas.
    const ctx = canvas.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
    // HTMLImageElement size
    if (element instanceof HTMLImageElement) {
      canvas.width = element.naturalWidth;
      canvas.height = element.naturalHeight;
      element.style.visibility = '';
      this.video.nativeElement.style.visibility = 'hidden';
      // Image center and auto scale
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', canvas.width + 'px');
      this.renderer.setStyle(this.elementRef.nativeElement, 'maxWidth', 100 + '%');
      this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'inline-block');
    }
    // HTMLVideoElement size
    if (element instanceof HTMLVideoElement) {
      canvas.width = element.videoWidth;
      canvas.height = element.videoHeight;
      element.style.visibility = '';
      this.canvas.nativeElement.style.visibility = 'hidden';
    }
    // Set width, height for video element
    UPDATE_WIDTH_HEIGHT_VIDEO(this.video.nativeElement, canvas);
    // clear frame
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    // Draw image
    ctx.drawImage(element, 0, 0, canvas.width, canvas.height);
    // Data image
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    // Draw frame
    const code = await zbarWasm.scanImageData(imageData);
    if (code && code.length) {
      // Decode
      code.forEach((s: any) => s.value = s.decode(this.decode && this.decode.toLocaleLowerCase()));
      // Overlay
      DRAW_RESULT_APPEND_CHILD(code, Object.freeze(this.canvas.nativeElement), this.resultsPanel.nativeElement, this.canvasStyles);
      // To blob and emit data
      const EMIT_DATA = () => {
        this.eventEmit(code);
        this.dataForResize = code;
      };
      // HTMLImageElement
      if (element instanceof HTMLImageElement) {
        callback(true);
        EMIT_DATA();
        VIBRATE(this.vibrate);
        PLAY_AUDIO(this.isBeep);
      }
      // HTMLVideoElement
      if (element instanceof HTMLVideoElement) {
        EMIT_DATA();
        VIBRATE(this.vibrate);
        PLAY_AUDIO(this.isBeep);
      }
    } else {
      callback(false);
      REMOVE_RESULT_PANEL(this.resultsPanel.nativeElement);
      this.dataForResize = [];
    }
  }

  /**
   * eventEmit
   * @param response 
   */
  private eventEmit(response: any = false): void {
    (response !== false) && this.data.next(response || []);
    (response !== false) && this.event.emit(response || []);
  }

  /**
   * Single-thread
   * Loop Recording on Camera
   * Must be destroy request 
   * Not using: requestAnimationFrame
   * @param delay 
   */
  private requestAnimationFrame(delay: number = 100): void {
    try {
      clearTimeout(this.rAF_ID);
      this.rAF_ID = setTimeout(() => {
        if (this.video.nativeElement.readyState === this.video.nativeElement.HAVE_ENOUGH_DATA) {
          delay = 0; // Appy first request
          WASM_READY() && this.drawImage(this.video.nativeElement);
          this.isStart && !this.isPause && this.requestAnimationFrame(delay);
        }
      }, /*avoid cache mediaStream*/ delay || this.fps);
    } catch (error) {
      clearTimeout(this.rAF_ID);
    }
  }

  /**
   * isReady
   */
  get isReady(): AsyncSubject<boolean> {
    return this.ready;
  }

  ngOnDestroy(): void {
    this.pause();
  }
}
