import { AsyncSubject } from "rxjs";
import { AS_COMPLETE, WASM_READY } from "./ngx-scanner-qrcode.helper";
import { Renderer2 } from "@angular/core";
import { WASMPROJECT, WASMREMOTE, WASMREMOTELATEST } from "./ngx-scanner-qrcode.default";

/**
 * LOAD_WASM
 * @param as 
 * @param renderer 
 * @returns 
 */
export const LOAD_WASM = (as: AsyncSubject<boolean | any> = new AsyncSubject(), renderer?: Renderer2): AsyncSubject<boolean | any> => {
    let retry = 0;
    const LOAD_WASM_RETRY = (isLoadWasmRemote = false) => {
        const DONE = () => {
            let timeoutId: any;
            try {
                const L = () => {
                    clearTimeout(timeoutId);
                    WASM_READY() ? setTimeout(() => AS_COMPLETE(as, true)) : timeoutId = setTimeout(() => L());
                }
                setTimeout(() => L());
                setTimeout(() => clearTimeout(timeoutId), 3000);
            } catch (error) {
                clearTimeout(timeoutId);
            }
        }
        const scriptRemote = (document.querySelectorAll(`script[src="${WASMREMOTE}"]`) as any as Array<HTMLElement>);
        const scriptRemoteLatest = (document.querySelectorAll(`script[src="${WASMREMOTELATEST}"]`) as any as Array<HTMLElement>);
        if (scriptRemote.length || scriptRemoteLatest.length) {
            DONE();
        } else {
            const scriptProject = (document.querySelectorAll(`script[src="${WASMPROJECT}"]`) as any as Array<HTMLElement>);
            if (scriptProject.length === 1) {
                DONE();
            } else {
                scriptProject.forEach(f => f.remove());
                if (renderer) {
                    const script = renderer.createElement("script") as HTMLScriptElement;
                    renderer.setAttribute(script, "src", isLoadWasmRemote ? WASMREMOTE : WASMPROJECT);
                    renderer.setAttribute(script, "type", "text/javascript");
                    renderer.setAttribute(script, "async", "");
                    renderer.appendChild(document.head, script);
                    script.onload = () => DONE();
                    script.onerror = () => {
                        if (retry < 2) {
                            document.head.removeChild(script);
                            LOAD_WASM_RETRY(true);
                        } else {
                            AS_COMPLETE(as, false, 'Could not load script ' + isLoadWasmRemote ? WASMREMOTE : WASMPROJECT);
                        }
                    }
                    retry += 1;
                } else {
                    const mod = document.createElement('script');
                    mod.setAttribute("src", isLoadWasmRemote ? WASMREMOTE : WASMPROJECT);
                    mod.setAttribute("type", "text/javascript");
                    mod.setAttribute("async", "");
                    document.head.appendChild(mod);
                    mod.onload = () => DONE();
                    mod.onerror = () => {
                        if (retry < 2) {
                            document.head.removeChild(mod);
                            LOAD_WASM_RETRY(true);
                        } else {
                            AS_COMPLETE(as, false, 'Could not load script ' + isLoadWasmRemote ? WASMREMOTE : WASMPROJECT);
                        }
                    }
                    retry += 1;
                }
            }
        }
    }
    LOAD_WASM_RETRY();
    return as;
}