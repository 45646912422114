import { NgModule } from "@angular/core";
import { NgxScannerQrcodeService } from "./ngx-scanner-qrcode.service";
import { NgxScannerQrcodeComponent } from "./ngx-scanner-qrcode.component";


@NgModule({
  declarations: [NgxScannerQrcodeComponent],
  exports: [NgxScannerQrcodeComponent],
  providers: [NgxScannerQrcodeService],
})
export class NgxScannerQrcodeModule { }
