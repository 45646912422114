import { AsyncSubject } from "rxjs";
import { BEEP, CANVAS_STYLES_LAYER, CANVAS_STYLES_TEXT, CONFIG_DEFAULT } from "./ngx-scanner-qrcode.default";
import { ScannerQRCodeConfig, ScannerQRCodeSelectedFiles } from "./ngx-scanner-qrcode.options";
declare var zbarWasm: any;

/**
 * WASM_READY
 * @returns 
 */
export var WASM_READY = () => ('zbarWasm' in window);

/**
 * OVERRIDES
 * @param variableKey 
 * @param config 
 * @param defaultConfig 
 * @returns 
 */
export const OVERRIDES = (variableKey: string, config: any, defaultConfig: any) => {
  if (config && Object.keys(config[variableKey]).length) {
    for (const key in defaultConfig) {
      const cloneDeep = JSON.parse(JSON.stringify({ ...config[variableKey], ...{ [key]: (defaultConfig as any)[key] } }));
      config[variableKey] = config[variableKey].hasOwnProperty(key) ? config[variableKey] : cloneDeep;
    }
    return config[variableKey];
  } else {
    return defaultConfig;
  }
};

/**
 * Rxjs complete
 * @param as 
 * @param data 
 * @param error 
 */
export const AS_COMPLETE = (as: AsyncSubject<any>, data: any, error = null) => {
  error ? as.error(error) : as.next(data);
  as.complete();
};

/**
 * CAMERA_BEEP
 * @param isPlay 
 * @returns 
 */
export const PLAY_AUDIO = (isPlay: boolean = false) => {
  if (isPlay === false) return;
  const audio = new Audio(BEEP);
  // when the sound has been loaded, execute your code
  audio.oncanplaythrough = () => {
    const promise = audio.play();
    if (promise) {
      promise.catch((e) => {
        if (e.name === "NotAllowedError" || e.name === "NotSupportedError") {
          // console.log(e.name);
        }
      });
    }
  };
};

/**
 * HAS_OWN_PROPERTY
 * Fix issue vs ng v 6-7-8
 * Optional chaining (?.) just have on ng v 9++
 * eg: HAS_OWN_PROPERTY(config, 'frameOptions.style') // output: boolean
 * @param obj 
 * @param propertyPath 
 * @returns 
 */
export const HAS_OWN_PROPERTY = (obj: any, propertyPath: string) => {
  const properties = propertyPath.split(".");
  for (let i = 0; i < properties.length; i++) {
    let prop = properties[i];
    if (obj && obj.hasOwnProperty(prop)) {
      obj = obj[prop];
    } else {
      return false;
    }
  }
  return true;
};

/**
 * DRAW_RESULT_APPEND_CHILD
 * @param code 
 * @param oriCanvas 
 * @param elTarget 
 * @param canvasStyles 
 */
export const DRAW_RESULT_APPEND_CHILD = (code: any[], oriCanvas: HTMLCanvasElement, elTarget: HTMLCanvasElement | HTMLDivElement, canvasStyles: CanvasRenderingContext2D[]) => {
  let widthZoom;
  let heightZoom;
  let oriWidth = oriCanvas.width;
  let oriHeight = oriCanvas.height;
  let oriWHRatio = oriWidth / oriHeight;
  let imgWidth = parseInt(getComputedStyle(oriCanvas).width);
  let imgHeight = parseInt(getComputedStyle(oriCanvas).height);
  let imgWHRatio = imgWidth / imgHeight;
  elTarget.innerHTML = '';

  if (oriWHRatio > imgWHRatio) {
    widthZoom = imgWidth / oriWidth;
    heightZoom = imgWidth / oriWHRatio / oriHeight;
  } else {
    heightZoom = imgHeight / oriHeight;
    widthZoom = (imgHeight * oriWHRatio) / oriWidth;
  }

  for (let i = 0; i < code.length; i++) {
    const _code = code[i];
    // New canvas
    let cvs = document.createElement("canvas");
    let ctx = cvs.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
    let loc: any = {};
    let X: any = [];
    let Y: any = [];
    let fontSize = 0;
    let svgSize = 0;

    if (canvasStyles.length === 2 && canvasStyles[1].font) {
      let num = canvasStyles[1].font.replace(/[^0-9]/g, '');
      if (/[0-9]/g.test(num)) {
        fontSize = parseFloat(num);
        svgSize = (widthZoom || 1) * fontSize;
        if (Number.isNaN(svgSize)) {
          svgSize = fontSize;
        }
      }
    }

    // Point x,y
    const points = _code.points;
    for (let j = 0; j < points.length; j++) {
      const xj = HAS_OWN_PROPERTY(points, `${j}.x`) ? points[j].x : 0;
      const yj = HAS_OWN_PROPERTY(points, `${j}.y`) ? points[j].y : 0;
      loc[`x${j + 1}`] = xj;
      loc[`y${j + 1}`] = yj;
      X.push(xj);
      Y.push(yj);
    }

    // Min max
    let maxX = Math.max(...X);
    let minX = Math.min(...X);
    let maxY = Math.max(...Y);
    let minY = Math.min(...Y);

    // Add class
    cvs.setAttribute('class', 'qrcode-polygon');

    // Size with screen zoom
    if (oriWHRatio > imgWHRatio) {
      cvs.style.top = minY * heightZoom + (imgHeight - imgWidth / oriWHRatio) * 0.5 + "px";
      cvs.style.left = minX * widthZoom + "px";
      cvs.width = (maxX - minX) * widthZoom;
      cvs.height = (maxY - minY) * widthZoom;
    } else {
      cvs.style.top = minY * heightZoom + "px";
      cvs.style.left = minX * widthZoom + (imgWidth - imgHeight * oriWHRatio) * 0.5 + "px";
      cvs.width = (maxX - minX) * heightZoom;
      cvs.height = (maxY - minY) * heightZoom;
    }

    // Style for canvas
    for (const key in canvasStyles[0]) {
      (ctx as any)[key] = (canvasStyles[0] as any)[key];
    }

    // polygon [x,y, x,y, x,y.....];
    const polygon = [];
    for (let k = 0; k < X.length; k++) {
      polygon.push((loc[`x${k + 1}`] - minX) * heightZoom);
      polygon.push((loc[`y${k + 1}`] - minY) * widthZoom);
    }

    // Copy array
    const shape = polygon.slice(0) as any;

    // Draw polygon
    ctx.beginPath();
    ctx.moveTo(shape.shift(), shape.shift());
    while (shape.length) {
      ctx.lineTo(shape.shift(), shape.shift()); //x,y
    }
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    if (fontSize) {
      // Tooltip result
      const qrcodeTooltipTemp = document.createElement('div');
      qrcodeTooltipTemp.setAttribute('class', 'qrcode-tooltip-temp');
      qrcodeTooltipTemp.innerText = _code.value;
      qrcodeTooltipTemp.style.maxWidth = ((oriWidth > window.innerWidth) ? window.innerWidth * 0.9 : oriWidth) + "px";
      qrcodeTooltipTemp.style.borderRadius = `clamp(1px, ${(widthZoom * fontSize) - 10}px, 3px)`;
      qrcodeTooltipTemp.style['paddingBlock'] = `clamp(1px, ${(widthZoom * fontSize) - 10}px, 3px)`; // top - bottom
      qrcodeTooltipTemp.style['paddingInline'] = `clamp(2.5px, ${(widthZoom * fontSize) - 6}px, 10px)`; // left - right
      const xmlString = `<svg xmlns="http://www.w3.org/2000/svg" width="${svgSize}" height="${svgSize}" viewBox="0 0 512 512"><rect x="128" y="128" width="336" height="336" rx="57" ry="57"></rect><path d="M383.5,128l.5-24a56.16,56.16,0,0,0-56-56H112a64.19,64.19,0,0,0-64,64V328a56.16,56.16,0,0,0,56,56h24"></path></svg>`;
      const xmlDom = new DOMParser().parseFromString(xmlString, 'application/xml');
      const svgDom = qrcodeTooltipTemp.ownerDocument.importNode(xmlDom.documentElement, true);
      svgDom.style.marginLeft = `clamp(1px, ${(widthZoom * fontSize) - 10}px, 3px)`; // left - right
      qrcodeTooltipTemp.appendChild(svgDom);
      svgDom.addEventListener("click", () => window.navigator['clipboard'].writeText(_code.value));
      qrcodeTooltipTemp.addEventListener("click", () => window.navigator['clipboard'].writeText(_code.value));

      // Tooltip box
      const qrcodeTooltip = document.createElement('div');
      qrcodeTooltip.setAttribute('class', 'qrcode-tooltip');
      qrcodeTooltip.appendChild(qrcodeTooltipTemp);
      heightZoom = imgHeight / oriHeight;
      widthZoom = (imgHeight * oriWHRatio) / oriWidth;
      qrcodeTooltip.style.fontSize = widthZoom * fontSize + 'px';
      qrcodeTooltip.style.top = minY * heightZoom + "px";
      qrcodeTooltip.style.left = minX * widthZoom + (imgWidth - imgHeight * oriWHRatio) * 0.5 + "px";
      qrcodeTooltip.style.width = (maxX - minX) * heightZoom + "px";
      qrcodeTooltip.style.height = (maxY - minY) * heightZoom + "px";

      // Result text
      const resultText = document.createElement('span');
      resultText.innerText = _code.value;

      // Set position result text
      resultText.style.top = minY * heightZoom + (-20 * heightZoom) + "px";
      resultText.style.left = minX * widthZoom + (imgWidth - imgHeight * oriWHRatio) * 0.5 + "px";

      // Style text
      resultText.style.fontSize = widthZoom * fontSize + 'px';
      if (canvasStyles.length == 2 && canvasStyles[1] && canvasStyles[1].font) {
        const ff = canvasStyles[1].font.split(' ')[1];
        resultText.style.fontFamily = ff;
        resultText.style.color = canvasStyles[1].fillStyle as string;
      }

      if (elTarget) {
        elTarget.appendChild(qrcodeTooltip);
        elTarget.appendChild(resultText);
      }
    }

    elTarget && elTarget.appendChild(cvs);
  };
}

/**
 * DRAW_RESULT_ON_CANVAS
 * @param code 
 * @param cvs 
 * @param canvasStyles 
 */
export const DRAW_RESULT_ON_CANVAS = (code: any[], cvs: HTMLCanvasElement, canvasStyles: CanvasRenderingContext2D[]) => {
  let ctx = cvs.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;

  for (let i = 0; i < code.length; i++) {
    const _code = code[i];
    let loc: any = {};
    let X: any = [];
    let Y: any = [];
    let fontSize = 0;

    if (canvasStyles.length === 2) {
      const fs = canvasStyles[1] && canvasStyles[1].font && canvasStyles[1].font.split(' ')[0];
      let num = fs && fs.replace(/[^0-9]/g, '');
      if (num && /[0-9]/g.test(num)) {
        fontSize = parseFloat(num);
      }
    }

    // Point x,y
    const points = _code.points;
    for (let j = 0; j < points.length; j++) {
      const xj = HAS_OWN_PROPERTY(points, `${j}.x`) ? points[j].x : 0;
      const yj = HAS_OWN_PROPERTY(points, `${j}.y`) ? points[j].y : 0;
      loc[`x${j + 1}`] = xj;
      loc[`y${j + 1}`] = yj;
      X.push(xj);
      Y.push(yj);
    }

    // Min max
    let minX = Math.min(...X);
    let minY = Math.min(...Y);

    const styleLayer = () => {
      // Style for canvas
      for (const key in canvasStyles[0]) {
        (ctx as any)[key] = (canvasStyles[0] as any)[key];
      }

      // polygon [x,y, x,y, x,y.....];
      const polygon = [];
      for (let k = 0; k < X.length; k++) {
        polygon.push(loc[`x${k + 1}`]);
        polygon.push(loc[`y${k + 1}`]);
      }

      // Copy array
      const shape = polygon.slice(0) as any;

      // Draw polygon
      ctx.beginPath();
      ctx.moveTo(shape.shift(), shape.shift());
      while (shape.length) {
        ctx.lineTo(shape.shift(), shape.shift()); //x,y
      }
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    }

    let cvs2 = document.createElement('canvas');
    const styleText = () => {
      if (canvasStyles.length === 2) {
        const ff = canvasStyles[1] && canvasStyles[1].font && canvasStyles[1].font.split(' ')[1];
        cvs2.height = cvs.height;
        cvs2.width = cvs.width;
        let ctx2 = cvs2.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
        ctx2.font = fontSize + `px ` + ff;
        for (const key in canvasStyles[1]) {
          (ctx2 as any)[key] = (canvasStyles[1] as any)[key];
        }
        FILL_TEXT_MULTI_LINE(ctx2, _code.value, minX, minY - 5);
      }
    }

    styleLayer();
    styleText();
    // Merge cvs2 into cvs
    ctx.drawImage(cvs2, 0, 0);
  };
}

/**
 * READ_AS_DATA_URL
 * @param file 
 * @param configs 
 * @returns 
 */
export const READ_AS_DATA_URL = (file: File, configs: ScannerQRCodeConfig): Promise<ScannerQRCodeSelectedFiles> => {
  /** overrides **/
  let decode = HAS_OWN_PROPERTY(configs, 'decode') ? configs.decode : CONFIG_DEFAULT.decode;
  let canvasStyles = HAS_OWN_PROPERTY(configs, 'canvasStyles') && configs.canvasStyles.length === 2 ? configs.canvasStyles : [CANVAS_STYLES_LAYER, CANVAS_STYLES_TEXT];
  let isBeep = HAS_OWN_PROPERTY(configs, 'isBeep') ? configs.isBeep : CONFIG_DEFAULT.isBeep;

  /** drawImage **/
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const objectFile = {
        name: file.name,
        file: file,
        url: URL.createObjectURL(file)
      };
      // Set the src of this Image object.
      const image = new Image();
      // Setting cross origin value to anonymous
      image.setAttribute('crossOrigin', 'anonymous');
      // When our image has loaded.
      image.onload = async () => {
        // Get the canvas element by using the getElementById method.
        const canvas = document.createElement('canvas');
        // HTMLImageElement size
        canvas.width = image.naturalWidth || image.width;
        canvas.height = image.naturalHeight || image.height;
        // Get a 2D drawing context for the canvas.
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        // Draw image
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        // Data image
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        // Scanner
        if (WASM_READY()) {
          const code = await zbarWasm.scanImageData(imageData);
          if (code && code.length) {
            // Decode
            code.forEach((s: any) => s.value = s.decode(decode && decode.toLocaleLowerCase()));
            // Overlay
            DRAW_RESULT_ON_CANVAS(code, canvas, canvasStyles);
            // Emit object
            const blob = await CANVAS_TO_BLOB(canvas);
            const url = URL.createObjectURL(blob);
            const blobToFile = (theBlob: any, fileName: string) => new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type });
            resolve(Object.assign({}, objectFile, { data: code, url: url, canvas: canvas, file: blobToFile(blob, objectFile.name) }));
            PLAY_AUDIO(isBeep);
          } else {
            resolve(Object.assign({}, objectFile, { data: code, canvas: canvas }));
          }
        }
      };
      // Set src
      image.src = objectFile.url;
    }
    fileReader.onerror = (error: any) => reject(error);
    fileReader.readAsDataURL(file);
  })
}

/**
 * Convert canvas to blob
 * canvas.toBlob((blob) => { .. }, 'image/jpeg', 0.95); // JPEG at 95% quality
 * @param canvas 
 * @param type 
 * @returns 
 */
export const CANVAS_TO_BLOB = (canvas: HTMLCanvasElement, type?: string): Promise<any> => {
  return new Promise((resolve, reject) => canvas.toBlob(blob => resolve(blob), type));
}

/**
 * Convert blob to file
 * @param theBlob 
 * @param fileName 
 * @returns 
 */
export const BLOB_TO_FILE = (theBlob: any, fileName: string): File => {
  return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type });
}

/**
 * FILES_TO_SCAN
 * @param files 
 * @param configs 
 * @param quality 
 * @param type 
 * @param as 
 * @returns 
 */
export const FILES_TO_SCAN = (files: File[] = [], configs: ScannerQRCodeConfig, quality?: number, type?: string, as = new AsyncSubject<ScannerQRCodeSelectedFiles[]>()): AsyncSubject<ScannerQRCodeSelectedFiles[]> => {
  COMPRESS_IMAGE_FILE(files, quality, type).then((_files: any) => {
    Promise.all(Object.assign([], files).map(m => READ_AS_DATA_URL(m, configs))).then((img: ScannerQRCodeSelectedFiles[]) => {
      AS_COMPLETE(as, img);
    }).catch((error: any) => AS_COMPLETE(as, null, error));
  });
  return as;
}

/**
 * FILL_TEXT_MULTI_LINE
 * @param ctx 
 * @param text 
 * @param x 
 * @param y 
 */
export const FILL_TEXT_MULTI_LINE = (ctx: CanvasRenderingContext2D, text: string, x: number, y: number) => {
  let lineHeight = ctx.measureText("M").width * 1.2;
  let lines = text.split("\n");
  for (var i = 0; i < lines.length; ++i) {
    ctx.fillText(lines[i], x, y);
    ctx.strokeText(lines[i], x, y);
    y += lineHeight;
  }
}

/**
 * COMPRESS_IMAGE_FILE
 * @param files 
 * @param quality 
 * @param type 
 * @returns 
 */
export const COMPRESS_IMAGE_FILE = async (files: File[], quality = 0.5, type = "image/jpeg") => {
  // No files selected
  if (!files.length || quality === 1) return files;

  const compressImage = async (file: File): Promise<File> => {
    // Get as image data
    const imageBitmap = await createImageBitmap(file);

    // Draw to canvas
    const canvas = document.createElement('canvas');
    canvas.width = imageBitmap.width;
    canvas.height = imageBitmap.height;
    const ctx = canvas.getContext('2d');
    (ctx as any).drawImage(imageBitmap, 0, 0);

    // Turn into Blob
    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, type, quality)
    ) as any;

    // Turn Blob into File
    return new File([blob], file.name, {
      type: blob.type,
    });
  };

  // We'll store the files in this data transfer object
  const dataTransfer = new DataTransfer();

  // For every file in the files list
  await (async () => {
    for (const file of files) {
      // We don't have to compress files that aren't images
      if (!file.type.startsWith('image')) {
        // Ignore this file, but do add it to our result
        dataTransfer.items.add(file);
        continue;
      }

      // We compress the file by 50%
      const compressedFile = await compressImage(file);

      // Save back the compressed file instead of the original file
      dataTransfer.items.add(compressedFile);
    }
  })();

  // return value new files list
  return dataTransfer.files;
}

/**
 * REMOVE_RESULT_PANEL
 * @param element 
 */
export const REMOVE_RESULT_PANEL = (element: HTMLElement) => {
  // clear text result and tooltip
  Object.assign([], element.childNodes).forEach(el => element.removeChild(el));
}

/**
 * RESET_CANVAS
 * @param canvas 
 */
export const RESET_CANVAS = (canvas: HTMLCanvasElement) => {
  // reset canvas
  const context = canvas.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
  // clear frame when reloop
  context.clearRect(0, 0, canvas.width, canvas.height);
}

/**
 * UPDATE_WIDTH_HEIGHT_VIDEO
 * @param video 
 * @param canvas 
 */
export const UPDATE_WIDTH_HEIGHT_VIDEO = (video: HTMLVideoElement, canvas: HTMLCanvasElement): void => {
  video.style.width = canvas.offsetWidth + 'px';
  video.style.height = canvas.offsetHeight + 'px';
}

/**
 * VIBRATE
 * Báº­t rung trÃªn mobile
 * @param time 
 */
export const VIBRATE = (time: number) => {
  time && IS_MOBILE() && window && window.navigator && window.navigator.vibrate(time);
};

/**
 * IS_MOBILE
 * @returns 
 */
export const IS_MOBILE = () => {
  const vendor = navigator.userAgent || navigator['vendor'] || window['opera'];
  const phone = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i;
  const version = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i;
  const isSafari = /^((?!chrome|android).)*safari/i;
  return !!(phone.test(vendor) || version.test(vendor.substr(0, 4))) && !isSafari.test(vendor);
};
